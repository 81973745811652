export class Enum {
    static GetAll() {
        let ris = [];
        for (let p in this) {
            ris.push(this[p]);
        }
        return ris;
    }
    static GetDictionary() {
        let ris = {};
        for (let p in this) {
            ris[p] = this[p];
        }
        return ris;
    }
}
export class AppRole extends Enum {
    static Admin = "Admin";
    static Office = "Office";
    static Collaboratore = "Collaboratore";
    static ClienteBig = "Cliente Big";
    static Cliente = "Cliente";
    static Commerciale = "Commerciale";
}
export class StatoReferral extends Enum {
    static Segnalato = "Segnalato";
    static Contattato = "Contattato";
    static Positivo = "Positivo";
    static Negativo = "Negativo";
}
export class ApprovationStatus extends Enum {
    static Inviato = "status_inviato";
    static Rifiutato = "status_rifiutato";
    static Incompleto = "status_incompleto";
    static Approvato = "status_approvato";
}
export class StatoTask extends Enum {
    static Disponibile = "Assegnazione in corso";
    static Standby = "Standby";
    static Assegnato = "Assegnato"; //meh
    static InLavorazione = "In lavorazione";
    static InModifica = "In modifica";
    static InApprovazione = "In consegna";
    static Concluso = "Concluso";
}
export class MailEvents extends Enum {
    static DisegniPronti = "Disegni pronti";
    static DisegniProntiOnOffice = "Disegni pronti OnOffice";
    static DisegniProntiGestim = "Disegni pronti Gestim";
    static ModifichePronte = "Modifiche pronte";
    static ModifichePronteOnOffice = "Modifiche pronte OnOffice";
    static ModifichePronteGestim = "Modifiche pronte Gestim";
    // static TaskAperto = "Task aperto";
    static PromemoriaCrediti = "Promemoria crediti";
    static NuovoCliente = "Nuovo cliente";
    static NotificaAssegnazioneArchitetto = "Notifica assegnazione architetto";
    static DatiBonifico = "Dati bonifico";
    static NotificaRivendita = "Notifica rivendita";
    static RisvegliaFreeze = "Risveglia freeze";
    static Standby = "Standby";
    static RegistrazioneConfermata = "Registrazione confermata";
}
export let MailTemplateConfig = [
    {
        templateName: MailEvents.DisegniPronti,
        variables: ["customer_name", "customer_surname", "task_name"]
    },
    {
        templateName: MailEvents.DisegniProntiGestim,
        variables: ["customer_name", "customer_surname", "task_name"]
    },
    {
        templateName: MailEvents.DisegniProntiOnOffice,
        variables: ["customer_name", "customer_surname", "task_name"]
    },
    {
        templateName: MailEvents.ModifichePronte,
        variables: ["customer_name", "customer_surname", "task_name"]
    },
    {
        templateName: MailEvents.ModifichePronteGestim,
        variables: ["customer_name", "customer_surname", "task_name"]
    },
    {
        templateName: MailEvents.ModifichePronteOnOffice,
        variables: ["customer_name", "customer_surname", "task_name"]
    },
    {
        templateName: MailEvents.NotificaAssegnazioneArchitetto,
        variables: ["architetto_name", "customer_name", "customer_surname", "task_name", "task_delivery_date"]
    },
    // {
    //     templateName: MailEvents.TaskAperto,
    //     variables: ["customer_name", "task_name", "task_link"]
    // },
    {
        templateName: MailEvents.PromemoriaCrediti,
        variables: ["customer_name", "customer_surname", "credits"]
    },
    {
        templateName: MailEvents.NuovoCliente,
        variables: ["customer_name", "customer_surname", "username"]
    },
    {
        templateName: MailEvents.DatiBonifico,
        variables: ["customer_name", "customer_surname", "amount", "product_name", "option_name"]
    },
    {
        templateName: MailEvents.NotificaRivendita,
        variables: ["customer_name", "customer_surname", "credits"]
    },
    {
        templateName: MailEvents.Standby,
        variables: ["customer_name", "customer_surname", "task_name"]
    },
    {
        templateName: MailEvents.RisvegliaFreeze,
        variables: ["customer_name", "customer_surname"]
    },
    {
        templateName: MailEvents.RegistrazioneConfermata,
        variables: ["customer_name", "customer_surname"]
    },
];
class MailTemplateVariables {
    templateName;
    variables;
}
