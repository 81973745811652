import * as OM from '@/model';
import * as VM from '@/viewmodel';
import { testMode } from './config';
import { AppRole } from './const';
import { StorageServices } from './services/StorageServices';
import { createStore } from 'vuex';
import planiDesigner from './planiDesigner/store';
let storeState = {
    config: new OM.AppConfig(),
    loginResponse: StorageServices.getLoginResponse() || new OM.LoginResponse(),
    consts: {
        localizedValues: [],
        enabledLanguages: [],
    },
    testMode: testMode,
    beforeNavCbs: [],
    afterNavCbs: [],
    isAdmin: () => {
        return storeState.loginResponse.role == AppRole.Admin;
    },
    isOffice: () => {
        return storeState.loginResponse.role == AppRole.Office;
    },
    isCollaboratore: () => {
        return storeState.loginResponse.role == AppRole.Collaboratore;
    },
    isClienteBig: () => {
        return storeState.loginResponse.role == AppRole.ClienteBig;
    },
    isCliente: () => {
        return storeState.loginResponse.role == AppRole.Cliente || storeState.loginResponse.role == AppRole.ClienteBig;
    },
    isCommerciale: () => {
        return storeState.loginResponse.role == AppRole.Commerciale;
    },
    globalSpinner: 0,
    customer: null,
    nextTaskId: 0,
    bgTasks: [],
    addBgTask(taskName, action) {
        let newTask = new VM.BgTask();
        newTask.taskName = taskName;
        newTask.progress = 0;
        newTask.action = action;
        newTask.taskId = this.nextTaskId++;
        this.bgTasks.push(newTask);
        newTask.action(newTask)
            .then(x => {
            setTimeout(() => {
                let indToRemove = this.bgTasks.indexOf(newTask);
                this.bgTasks.splice(indToRemove, 1);
            }, 2000);
        });
    },
    planiDesigner: planiDesigner
};
let vuestore = {
    state: storeState,
};
let store = createStore(vuestore);
export default store;
